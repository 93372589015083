import { Box, Button, Chip, Grid, Typography } from "@mui/material"
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useState } from "react";
import { Stack } from "@mui/system";
import { CheckOutlined, FmdGood, Mail, Phone } from "@mui/icons-material";
import { INFO, } from "../constants";
import { Link } from "../../components/Link";
import { ArrowCircleRight } from "iconsax-react";
import { Calendar } from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import moment from "moment"

const MyCalendar = () => {
  const staticDate1 = moment('2023-05-12');
  const [selectedDate, setSelectedDate] = useState(staticDate1);

  const handleViewChange = (e) => {
    const currMonth = e.activeStartDate.getMonth();
    if (currMonth === staticDate1.month())
      setSelectedDate(staticDate1)
  }

  return <Calendar onActiveStartDateChange={handleViewChange} defaultValue={null} value={selectedDate} />
}



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const Main = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return <Box>
    <Box pt={24} pb={8} sx={{ backgroundImage: `url(${INFO[value].banner})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundColor: "#000000", transition: "background-image 0.3s ease" }}>
      <Box width={{ xs: "90%", md: "80%" }} m="auto" maxWidth={1400} >
        <Box mb={2}>
          <Chip color="blue" label="Anmeldungen geöffnet" size="large" sx={{ fontWeight: 600, borderRadius: 2 }} />
        </Box>
        <Typography variant="h1" color="white.main" fontWeight={500}>{INFO[value].title}</Typography>
      </Box>
    </Box>
    <Box width={{ xs: "90%", md: "80%" }} m="auto" py={4} maxWidth={1400}>
      <Grid container columnSpacing={8} columns={{ xs: 6, lg: 12 }}>
        <Grid item xs={6} lg={7}>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange}>
                <Tab label="Einzelheiten" {...a11yProps(0)} />
                {/* <Tab label="Teas" {...a11yProps(1)} disabled /> */}
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <Stack spacing={3} alignItems="start">
                <Stack direction="row" spacing={2} alignItems="center">
                  <Stack width={40} height={40} alignItems="center" justifyContent="center" bgcolor="black.main" color="white.main" borderRadius={3}>
                    <FmdGood />
                  </Stack>
                  <Typography variant="body1" fontWeight={500} maxWidth={400}>{INFO[value].location}</Typography>
                </Stack>
                <Stack spacing={3}>
                  {Object.keys(INFO[0].content).map((title, idx) =>
                    <Stack key={idx} spacing={1.5}>
                      <Typography variant="h5" fontWeight={600}>{title}</Typography>
                      {
                        INFO[0].content[title].map((elem, idx) =>
                          <Stack key={idx} direction="row" spacing={1}>
                            <Stack width={24} height={24} alignItems="center" justifyContent="center" bgcolor="green.main" color="white.main" borderRadius={100}>
                              <ArrowCircleRight />
                            </Stack>
                            <Typography variant="body1" fontWeight={600}>{elem}</Typography>
                          </Stack>)
                      }
                    </Stack>
                  )}
                </Stack>
                <Stack direction={{ xs: "column", sm: "row" }} spacing={4} alignItems="center">
                  <Stack>
                    <Stack direction="row" spacing={2}>
                      <Typography variant="h6" fontWeight={600} >CHF 120</Typography>
                      {/* <Chip color="blue" label="Save 40%" size="small" sx={{ fontWeight: 600 }} /> */}
                    </Stack>
                  </Stack>
                  <Link path="/register" component={
                    <Button size="large" variant="contained" sx={{ px: 6 }}>Apply now</Button>
                  } />
                </Stack>
              </Stack>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Stack spacing={2} alignItems="start">
                <Stack direction="row" spacing={2} alignItems="center">
                  <Stack width={40} height={40} alignItems="center" justifyContent="center" bgcolor="black.main" color="white.main" borderRadius={3}>
                    <FmdGood />
                  </Stack>
                  <Typography variant="body1" fontWeight={500}>Rössli restaurant, Hemmike</Typography>
                </Stack>
                <Typography variant="h5" fontWeight={600}>Details</Typography>
                {INFO[1].details.map((elem, idx) =>
                  <Stack key={idx} direction="row" alignItems="center" spacing={1}>
                    <Stack width={24} height={24} alignItems="center" justifyContent="center" bgcolor="green.main" color="white.main" borderRadius={100}>
                      <CheckOutlined />
                    </Stack>
                    <Typography variant="body1" fontWeight={600}>{elem}</Typography>
                  </Stack>
                )}
                <Stack direction={{ xs: "column", sm: "row" }} spacing={4} alignItems="center">
                  <Stack>
                    <Typography variant="body1" fontWeight={500} color="text.secondary" sx={{ textDecoration: "line-through" }}>CHF 200</Typography>
                    <Stack direction="row" spacing={2}>
                      <Typography variant="h6" fontWeight={600} >CHF 120</Typography>
                      <Chip color="blue" label="Save 40%" size="small" sx={{ fontWeight: 600 }} />
                    </Stack>
                  </Stack>
                  <Link path="/register" component={
                    <Button size="large" variant="contained" sx={{ px: 6 }}>Apply now</Button>
                  } />
                </Stack>
              </Stack>
            </TabPanel>
          </Box>
          <Stack p={4} spacing={1}>
            <Typography variant="h6" fontWeight={600} >Kurse geplant am:</Typography>
            <MyCalendar />
          </Stack>
        </Grid>
        <Grid item xs={6} lg={5}>
          <Stack spacing={3} boxSizing="border-box" bgcolor="#f2f3f5" borderRadius={4} p={4} minWidth="320px">
            <img src={INFO[value].organiser.avatar} style={{ borderRadius: 16 }} alt={`${INFO[value].organiser.name}-avatar`} height={120} width={120} />
            <Stack>
              <Typography variant="body1" fontWeight={600} >Hello, I'm</Typography>
              <Typography variant="h4" className="notranslate" fontWeight={600} >{INFO[value].organiser.name}</Typography>
            </Stack>
            <Typography variant="body1" fontWeight={500} >{INFO[value].organiser.desc}</Typography>
            <Stack spacing={2}>
              <Link url={`tel:${INFO[value].organiser.phone}`} component={
                <Stack direction="row" spacing={2}>
                  <Phone />
                  <Typography variant="body1" fontWeight={600} >{INFO[value].organiser.phone}</Typography>
                </Stack>
              } />
              <Link url={`mailto:${INFO[value].organiser.email}`} component={
                <Stack direction="row" spacing={2}>
                  <Mail />
                  <Typography variant="body1" fontWeight={600} >{INFO[value].organiser.email}</Typography>
                </Stack>
              } />
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  </Box>
}