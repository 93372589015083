import { ThemeProvider } from "@mui/material";
import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "./layout";
import { Main, Contact, Register, TOS } from "./pages";
import theme from "./theme";


function App() {
  const [userHasScrolled, setUserHasScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      window.scrollY === 0
        ? setUserHasScrolled(false)
        : setUserHasScrolled(true);

    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  return <ThemeProvider theme={theme}>
    <Routes>
      <Route
        path="/"
        element={
          <Layout
            userHasScrolled={userHasScrolled}
          />
        }
      >
        <Route index element={<Main />} />
        <Route path="/register" element={<Register />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/terms-of-service" element={<TOS />} />
      </Route>
    </Routes>
  </ThemeProvider>
}

export default App;
