import { Button, Stack, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { Link } from "../Link"

export const Navbar = ({
  userHasScrolled, }) => {
  return <Box width="100%"
    boxSizing="border-box"
    py={1} bgcolor="white.main" sx={{
      position: "sticky",
      top: "0",
      zIndex: 99,
      transition: "all ease 0.3s",
    }}>
    <Box
      mx="auto"
      maxWidth={1400}
      width={{ xs: "90%", lg: "80%" }}
    >
      <Stack justifyContent="space-between" alignItems="center" direction="row">
        <Link path="/" component={<Typography fontFamily="serif" variant="h4" fontWeight={600} fontStyle="italic">Geheimnisvoll</Typography>} />
        <Link path="/contact-us" component={
          <Button color="black" size="medium" variant={userHasScrolled ? "contained" : "outlined"}>Contact us</Button>
        } />
      </Stack>
    </Box >
  </Box>
}