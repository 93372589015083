import { Box, Divider, Stack, Typography } from "@mui/material"
import { Link } from "../Link"

export const Footer = () => {
  return <Box >
    <Divider />
    <Box width={{ xs: "90%", md: "80%" }} py={4} m="auto" maxWidth={1400}>
      <Stack justifyContent="space-between" spacing={2} direction={{ xs: "column", sm: "row" }}>
        <Typography variant="h6" fontWeight={600}>Indischkochkurs</Typography>
        <div id="google_translate_element"></div>
        <Link path="/terms-of-service" component={
          <Typography variant="body2" fontWeight={500}>Terms or service</Typography>
        } />
      </Stack>
    </Box>
  </Box>
}