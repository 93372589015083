import { Button, Grid, TextField, Typography } from "@mui/material"
import { Box, Stack } from "@mui/system"

export const Contact = () => {
  return <Box>
    <Grid container columns={{ xs: 6, lg: 12 }} alignItems="center" height="100vh">
      <Grid item xs={6} lg={6}>
        <Box width={{ xs: "90%", lg: "60%" }} m="auto" py={8}>
          <Stack spacing={3}>
            <Typography variant="h1" fontWeight={500}>Contact us</Typography>
            <form name="contact" method="POST" data-netlify="true">
              <input type="hidden" name="form-name" value="contact" />
              <Stack
                spacing={4}
                bgcolor="white.main"
                borderRadius={2}
                maxWidth={600}
              >
                <Stack direction="row" spacing={2}>
                  <TextField
                    sx={{ width: "100%" }}
                    label="First Name"
                    name="first-name"
                    variant="outlined"
                    required
                  />
                  <TextField
                    sx={{ width: "100%" }}
                    label="Last Name"
                    name="last-name"
                    variant="outlined"
                    required
                  />
                </Stack>
                <TextField
                  label="Email"
                  name="email"
                  type="email"
                  variant="outlined"
                  required
                />
                <TextField
                  label="Phone"
                  name="phone"
                  type="number"
                  variant="outlined"
                  required
                />
                <TextField
                  label="Message (Optional)"
                  name="message"
                  rows={4}
                  variant="outlined"
                  multiline
                />
                <Button
                  type="submit"
                  color="black"
                  variant="contained"
                  size="large"
                  sx={{ width: "fit-content" }}
                >
                  Send message
                </Button>
              </Stack>
            </form>
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={0} lg={6} sx={{ backgroundImage: "url(/images/contact.jpg)", backgroundRepeat: "no-repeat", backgroundSize: "cover", height: "100%" }}></Grid>
    </Grid>
  </Box>
}