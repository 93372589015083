export const INFO = [
  {
    title: "Indisch Kochen Lernen",
    banner: "/images/cooking-banner.jpg",
    location: "Restaurant Rössli, Buusnerstrasse 6, 4465 Hemmiken (BL), Parkplätze stehen begrenzt zur Verfügung",
    content: {
      "Die Idee": [
        `Neugierigen die indische Küche näherbringen – ohne Geschmacksverstärker und ohne jegliche E-Stoffe. Erklären von verschiedenen Gewürzen, die sowohl zum Kochen verwendet werden können, aber auch als natürliche Heilmittel dienen.`,
        `Wir kochen gemeinsam unter meiner Anleitung. Die Zutaten sind Produkte aus der Schweiz, regional und saisonal. Nur die Gewürze kommen aus Indien, da ich diese immer wieder selbst aus meiner Heimat mitbringe.`
      ],
      "Das Konzept": [
        "Gemeinsam bereiten wir ein 5-Gang-Menu zu, passend zur Saison. Inklusiv sind ein Wein, passend zum Hauptgang und alkoholfreie Getränke.",
        "Das gemeinsame Essen ist der geniesserische Abschluss des Kochkurses.",
        "Den Kochkurs biete ich 2x im Quartal an. ",
        "Denselben Kochkurs biete ich auch für Firmen oder für Privatanlässe bei Ihnen zu Hause an.",
        "Der Kurs dauert ca. von 17 Uhr – 22 Uhr. ",
      ],
      "Bedingungen": [
        "Mindestteilnehmerzahl: 8 Personen bis maximal 12 Personen",
        "Mitbringen: eine Kochschürze, gute Laune und Lust, etwas Neues auszuprobieren",
      ],
      "Stornierung": [
        "Kostenlos bis 4 Tage vor Kursbeginn.",
        "Danach wird die Kursgebühr nicht mehr zurückerstattet."
      ],
      "Bezahlung": [
        "Woche im Voraus Einzahlung auf nachfolgendes Konto:",
        "IBAN: CH47 0900 0000 8555 9672 4"
      ],
    },
    organiser: {
      avatar: "/images/avatars/1.jpg",
      name: "Francis Lopes",
      desc: `
      1970 geboren und aufgewachsen in Goa, Indien. Koch- und Kellnerausbildung in Indien, EFZ-Zertifikat in der Schweiz nachgeholt. Mehrere Jahre Erfahrung auf Kreuzfahrtschiffen und in den USA gesammelt. 2001 ausgewandert der Liebe wegen in die Schweiz. 
      `,
      phone: "+41 79 232 09 74",
      email: "kochenmitfrancis5@gmail.com"
    }
  }, {
    title: "100% Natural Tees",
    banner: "/images/tea-banner.jpg",
    details: [
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    ],
    organiser: {
      avatar: "/images/avatars/2.jpg",
      name: "Susan Schmidt",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat",
      phone: "+4179-232-09-74",
      email: "example@hotmail.com"
    }
  }
] 
