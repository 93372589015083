import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { Footer } from "./components/Footer";
import { Navbar } from "./components/Navbar";

export default function Layout({
  userHasScrolled,
}) {
  return (
    <Box>
      <Navbar
        userHasScrolled={userHasScrolled}

      />
      <Outlet />
      <Footer />
    </Box>
  );
}
